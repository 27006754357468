import React from "react";
import "./OrderView.css";
import { gql } from 'apollo-boost';
import { useQuery } from '@apollo/react-hooks';
import OrderViewSkeleton from "./OrderViewSkeleton";


const ORDERS_QUERY = gql`
			query getOrderDetails($id: Int) {
				salesOrder(id: $id) {
					increment_id
					customer_name
					grand_total
					created_at
					invoice_id
					subtotal
					telephone
					payment
					tax
					status
					discount_amount
					items{
						id
						name
						price
						qty_ordered
						tax_percent
						tax_amount
						discount_amount
						row_total
					}
				}
			  }
		`;

function OrderView(props) {
	console.log(props.orderId);
	let datavariables = { id: props.orderId };
	const { data } = useQuery(ORDERS_QUERY, {
		variables: datavariables
	});
	
	if (typeof data != 'undefined') {
		localStorage.setItem('orderData',JSON.stringify(data.salesOrder));
		return (
				<div className="modal-body order-modal-body">
					<div className="row">
						<div className="col-6 text-right border-right my-1">
							<span className="order-info-label">Order ID : </span>
							<span>{data.salesOrder.increment_id}</span>
						</div>
						<div className="col-6 text-left">
							<span className="order-info-label">Order Date : </span>
							<span>{data.salesOrder.created_at}</span>
						</div>
					</div>
					<div className="row">
						<div className="col-6 text-right border-right my-1 order-info-label">
							<span className="order-info-label">Customer Name :</span>
							<span>{data.salesOrder.customer_name}</span>
							 
						</div>
						<div className="col-6 text-left">
							<span className="order-info-label">Customer Phone :</span>
							<span>{data.salesOrder.telephone}</span>
      					</div>
					</div>
					<div className="row">
						<div className="col-6 text-right border-right my-1 order-info-label">
							<span className="order-info-label">Payment Mode : </span>
							<span>{data.salesOrder.payment}</span>
						</div>
						<div className="col-6 text-left">
							<span className="order-info-label">Invoice Number : </span>
							<span>{data.salesOrder.invoice_id}</span>
      					</div>
					</div>
					<div className="row">
						<div className="col-6 text-right border-right my-1 order-info-label">
							<span className="order-info-label">Status : </span>
							<span>{data.salesOrder.status}</span>
						</div>
					</div>
					<div className="row">
						<div className="col-12">
							<h5 className="order-items-title text-left mt-4 mb-0 px-3">Order Items</h5>
						</div>
					</div>
					<div className="row">
						<div className="col-sm-12 itm-table">
							<table className="custom-table table order-specific-iems">
								<thead >
									<tr >
										<th scope="col">Name</th>
										<th scope="col">Price</th>
										<th scope="col">Quantity</th>
										<th scope="col">Tax Amount</th>
										<th scope="col">Tax Percent</th>
										<th scope="col">Discount Amount</th>
										<th scope="col">Total</th>
									</tr>
								</thead>
								<tbody >
									{data.salesOrder.items.map((value, index) => {
										return (
											<tr key={index}>
												<td >
													{value.name}
												</td>
												<td >
													{value.price}
												</td>
												<td >
													{value.qty_ordered}
												</td>  
												<td >
													{value.tax_amount}
												</td>  
												<td >
													{value.tax_percent + "%"}
                  								</td>  
												<td >
													{value.discount_amount}
                  								</td>
												<td >{value.row_total}</td>
											</tr>
										);
									})}
								</tbody>
							</table>
							<div className="row justify-content-end">
								<div className="col-sm-12 col-md-7 col-lg-4">
									<table className="custom-table table subtotal-table">
										<tbody>
											<tr>
												<th className="sub-heading" scope="row">Sub-Total</th>
												<td >{data.salesOrder.subtotal}</td>
											</tr>
											<tr>
												<th className="sub-heading" scope="row">Tax</th>
												<td >{data.salesOrder.tax}</td>
											</tr>
											<tr>
												<th className="sub-heading total" scope="row">Discount</th>
												<td className="total">{data.salesOrder.discount_amount}</td>
											</tr>
											<tr>
												<th className="sub-heading total" scope="row">Total</th>
												<td className="total">{data.salesOrder.grand_total}</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
				</div>
		);
	} else {
		return (
			<div>
				<OrderViewSkeleton />
			</div>
		);
	}

}
export default OrderView;
