import { useMutation } from '@apollo/react-hooks';
import React from 'react';
import { Store } from '../Store';
import { gql } from 'apollo-boost';
import { useAlert } from "react-alert";

const CREATE_INVOICE_AND_SHIPMENT_MUTATION = gql`
mutation createInvoiceAndShipment($agent_id: Int!, $order_id: Int!, $payment_information: String!) {
  createInvoiceAndShipment(agent_id: $agent_id, order_id: $order_id, payment_information: $payment_information) {
    invoice_success,
    shipment_success,
    invoice_id,
    shipment_id
  }
}
`;

const CREATE_CREDITMEMO_MUTATION = gql`
mutation createCreditMemo($agent_id:Int!, $order_id: Int!, $itemData: String!, $adjustment_refund: Float!, $adjustment_fee: Float!) {
  createCreditMemo(agent_id:$agent_id, order_id:$order_id ,itemData: $itemData, adjustment_refund: $adjustment_refund, adjustment_fee: $adjustment_fee) {
    success,
    creditmemo_id
  }
}
`;

const UPDATEQTY_MUTATION = gql`
mutation updateQty($product_id: String!, $product_qty: String) {
  updateQty(product_id:$product_id ,product_qty: $product_qty) {
    success,
    message
  }
}
`;

/**
 *
 * @param {*} props.query the footer data query
 */
export const useCart = props => {
  const { dispatch } = React.useContext(Store);
  const [customerToken, { data }] = useMutation(props.CUSTOMERTOKEN_MUTATION);

  const customerData = data;

  const getCutomerToken = async (details) => {
    await customerToken({ variables: { customerId: details.customerId } });
    getQuoteDetails();
  };
  if (typeof customerData != 'undefined') {
    localStorage.setItem('customerToken', customerData.customerToken.token);
    localStorage.setItem('quote_id', customerData.customerToken.quote_id);
  }

  const getQuoteDetails = async (details) => {
    localStorage.getItem('quote_id');
    const token = localStorage.getItem('customerToken');
    const data = await fetch(process.env.REACT_APP_URL_WITH_PORT + '/rest/V1/carts/mine/totals', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    });
    const cartItems = await data.json();
    localStorage.setItem("cartDetails", JSON.stringify(cartItems));
    return dispatch({
      type: 'cart_items',
      payload: cartItems
    });
  };
  return {
    customerData,
    getCutomerToken
  };
};

/**
 *
 * @param {*} props.query the footer data query
 */

export const useProduct = props => {
  const { state, dispatch } = React.useContext(Store);
  const addProductToCart = async (details) => {
    dispatch({
      type: 'show_loader',
      payload: true
    });
    const quoteId = localStorage.getItem('quote_id');
    const token = localStorage.getItem('customerToken');
    const data = await fetch(process.env.REACT_APP_URL_WITH_PORT + '/rest/V1/carts/mine/items', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      },
      body: JSON.stringify({ "cartItem": { "quote_id": quoteId, "sku": details.sku, "qty": 1 } })
    });
   
    const quoteData = await data.json();

    
    
    let agentDetails = JSON.parse(localStorage.getItem('agentDetails'));
    let customerDetails = JSON.parse(localStorage.getItem('customerDetail'));
    let shippingInfoRequestBody = {
      "addressInformation": {
        "shippingAddress": {
          "region": agentDetails.store_region,
          "region_id": agentDetails.store_region_id,
          "country_id": agentDetails.store_country,
          "street": [
            agentDetails.store_street
          ],
          "telephone": customerDetails.contact_number ? customerDetails.contact_number : process.env.REACT_APP_DEFAULT_CONTACT_NUMBER, 
          "postcode": agentDetails.store_pincode,
          "city": agentDetails.store_city,
          "firstname": customerDetails.firstname,
          "lastname": customerDetails.lastname,
          "email": customerDetails.email,
          "sameAsBilling": 1
        },
        "billingAddress": {
          "region": agentDetails.store_region,
          "region_id": agentDetails.store_region_id,
          "country_id": agentDetails.store_country,
          "street": [
            agentDetails.store_street
          ],
          "telephone": customerDetails.contact_number ? customerDetails.contact_number : process.env.REACT_APP_DEFAULT_CONTACT_NUMBER, 
          "postcode": agentDetails.store_pincode,
          "city": agentDetails.store_city,
          "firstname": customerDetails.firstname,
          "lastname": customerDetails.lastname,
          "email": customerDetails.email,
        },
        "shipping_method_code": "posshipping",
        "shipping_carrier_code": "posshipping"
      }
    };
    if (agentDetails.store_region_id == "0") {
      delete shippingInfoRequestBody['addressInformation']['shippingAddress']['region_id'];
      delete shippingInfoRequestBody['addressInformation']['billingAddress']['region_id'];
    }
    const shipdata = await fetch(process.env.REACT_APP_URL_WITH_PORT + '/rest/V1/carts/mine/shipping-information', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      },
      body: JSON.stringify(shippingInfoRequestBody)
    });

    await shipdata.json();

    
    getQuoteDetails();
    dispatch({
      type: 'show_loader',
      payload: false
    });
  };

  const getQuoteDetails = async (details) => {
    const quoteId = localStorage.getItem('quote_id');
    const token = localStorage.getItem('customerToken');
    const data = await fetch(process.env.REACT_APP_URL_WITH_PORT + '/rest/V1/carts/mine/totals', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    });
    const cartItems = await data.json();
    localStorage.setItem("cartDetails", JSON.stringify(cartItems));
    return dispatch({
      type: 'cart_items',
      payload: cartItems
    });
  };

  const removeProductFromCart = async (details) => {
    if (details.item_id) {
      dispatch({
        type: 'show_loader',
        payload: true
      });
      const token = localStorage.getItem('customerToken');
      const response = await fetch(process.env.REACT_APP_URL_WITH_PORT + '/rest/V1/carts/mine/items/' + details.item_id, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        }
      });
      const responseData = await response.json();
      getQuoteDetails();
      dispatch({
        type: 'show_loader',
        payload: false
      });
    }
  }

  const updateQuoteItemQtyInCart = async (details) => {
    if (details.item_id) {
      dispatch({
        type: 'show_loader',
        payload: true
      });
      const quoteId = localStorage.getItem('quote_id');
      const token = localStorage.getItem('customerToken');
      const response = await fetch(process.env.REACT_APP_URL_WITH_PORT + '/rest/V1/carts/mine/items/' + details.item_id, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify({ "cartItem": { "quote_id": quoteId, "sku": details.sku, "qty": details.qty } })
      });
      const responseData = await response.json();
      getQuoteDetails();
      dispatch({
        type: 'show_loader',
        payload: false
      });
    }
  }

  const applyCoupon = async (details) => {
    if (details.coupon_code) {
      dispatch({
        type: 'show_loader',
        payload: true
      });
      const quoteId = localStorage.getItem('quote_id');
      const token = localStorage.getItem('customerToken');
      const response = await fetch(process.env.REACT_APP_URL_WITH_PORT + '/rest/V1/carts/mine/coupons/' + details.coupon_code, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        }
      });
      let responseData = await response.json();
      let couponResponse = "";
      if (responseData.message) {
        couponResponse = responseData.message
      } else if (responseData == true) {
        couponResponse = responseData;
      }
      localStorage.setItem('coupon_response', couponResponse);
      await getQuoteDetails();
      dispatch({
        type: 'show_loader',
        payload: false
      });

      
    }
  }

  const removeCoupon = async (details) => {
    dispatch({
      type: 'show_loader',
      payload: true
    });
    const token = localStorage.getItem('customerToken');
    const response = await fetch(process.env.REACT_APP_URL_WITH_PORT + '/rest/V1/carts/mine/coupons', {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    });
    let responseData = await response.json();
    let couponResponse = "";
    if (responseData.message) {
      couponResponse = responseData.message
    } else if (responseData == true) {
      couponResponse = responseData;
    }
    localStorage.setItem('coupon_removed_response', couponResponse);
    getQuoteDetails();
    dispatch({
      type: 'show_loader',
      payload: false
    });
  }

  return {
    addProductToCart,
    getQuoteDetails,
    removeProductFromCart,
    updateQuoteItemQtyInCart,
    applyCoupon,
    removeCoupon
  };
};

export const useCheckout = props => {
  const { state, dispatch } = React.useContext(Store);
  const messageAlert = useAlert();
  const [createInvoiceAndShipment, { data }] = useMutation(CREATE_INVOICE_AND_SHIPMENT_MUTATION);
  const checkout = async (details) => {
    dispatch({
      type: 'show_loader',
      payload: true
    });
    const quoteId = localStorage.getItem('quote_id');
    const token = localStorage.getItem('customerToken');
    let agentDetails = JSON.parse(localStorage.getItem('agentDetails'));
    let customerDetails = JSON.parse(localStorage.getItem('customerDetail'));
    let shippingInfoRequestBody = {
      "addressInformation": {
        "shippingAddress": {
          "region": agentDetails.store_region,
          "region_id": agentDetails.store_region_id,
          "country_id": agentDetails.store_country,
          "street": [
            agentDetails.store_street
          ],
          "telephone": customerDetails.contact_number ? customerDetails.contact_number : process.env.REACT_APP_DEFAULT_CONTACT_NUMBER, 
          "postcode": agentDetails.store_pincode,
          "city": agentDetails.store_city,
          "firstname": customerDetails.firstname,
          "lastname": customerDetails.lastname,
          "email": customerDetails.email,
          "sameAsBilling": 1
        },
        "billingAddress": {
          "region": agentDetails.store_region,
          "region_id": agentDetails.store_region_id,
          "country_id": agentDetails.store_country,
          "street": [
            agentDetails.store_street
          ],
          "telephone": customerDetails.contact_number ? customerDetails.contact_number : process.env.REACT_APP_DEFAULT_CONTACT_NUMBER, 
          "postcode": agentDetails.store_pincode,
          "city": agentDetails.store_city,
          "firstname": customerDetails.firstname,
          "lastname": customerDetails.lastname,
          "email": customerDetails.email,
        },
        "shipping_method_code": "posshipping",
        "shipping_carrier_code": "posshipping"
      }
    };
    if (agentDetails.store_region_id == "0") {
      delete shippingInfoRequestBody['addressInformation']['shippingAddress']['region_id'];
      delete shippingInfoRequestBody['addressInformation']['billingAddress']['region_id'];
    }
   
    let orderRequestBody = {
      "paymentMethod": {
        "method": "cashondelivery"
      }
    }
    if (localStorage.getItem('payment_mode') == "card") {
      orderRequestBody = {
        "paymentMethod": {
          "method": "paybycard"
        }
      }
    }
    const orderData = await fetch(process.env.REACT_APP_URL_WITH_PORT + '/rest/V1/carts/mine/order', {
      method: 'Put',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      },
      body: JSON.stringify(orderRequestBody)
    });
    let orderResponse = await orderData.json();
    if (orderData.ok) {
      await createInvoiceAndShipment({ variables: { 
                                                    agent_id: agentDetails.id,
                                                    order_id: orderResponse,
                                                    payment_information: localStorage.getItem('paymentInformation')
                                                  } 
                                      });
      localStorage.setItem('placedOrderId',orderResponse);
      localStorage.removeItem('cartDetails');
      localStorage.removeItem('customerId');
      localStorage.removeItem('customerName');
      localStorage.removeItem('customerToken');
      localStorage.removeItem('quote_id');
      localStorage.removeItem('payment_mode');
      localStorage.removeItem('paymentInformation');
      messageAlert.success("Order placed Successfully.");
    }
    dispatch({
      type: 'checkout_success',
      payload: true
    });
    dispatch({
      type: 'show_loader',
      payload: false
    });
    return dispatch({
      type: 'cart_items',
      payload: 'refresh_cart'
    });
  }
  return {
    checkout
  };
};

export const useRefund = () => {
  const [createCreditMemo, {loading,error, data }] = useMutation(CREATE_CREDITMEMO_MUTATION);
  const messageAlert = useAlert();
  const refundOrder = async (details) => {
   const response = await createCreditMemo({ variables: details });
    
  }
  
  return {refundOrder,
   resdata: data && data.createCreditMemo,
   reserror:error
  };
}

export const useUpdateQty = () => {
  const [updateProductQty, {loading,error, data }] = useMutation(UPDATEQTY_MUTATION);
  const messageAlert = useAlert();  
  const updateQty = async (details) => {
   const response = await updateProductQty({ variables: details });
    
  }
  return {updateQty,
   productData: data && data.updateQty,
   qtyUpdateError: error
  };
}
